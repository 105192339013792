import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { Inicio } from "../components/home/Inicio";
import { Contacto } from "../components/contact/Contacto";
import { HeaderNav } from "../components/layout/HeaderNav";
import { Footer } from "../components/layout/Footer";
import { Proyecto } from "../components/project/Proyecto";
import { Proyectos } from "../components/projects/Proyectos";
import { About } from "../components/about/About";

export const MisRutas = () => {
  return (
    <BrowserRouter>
      {/* HEADER Y NAVEGACION */}
      <HeaderNav />

      {/* CONTENIDO CENTRAL */}
      <section className="content">
        <Routes>
          <Route path="/" element={<Navigate to={"/inicio"} />} />
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/about" element={<About />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/proyecto/:id" element={<Proyecto />} />
          <Route
            path="*"
            element={
              <div className="page">
                <h1 className="heading">Error 404</h1>
              </div>
            }
          />
        </Routes>
      </section>

      {/*FOOTER */}
      <Footer />
    </BrowserRouter>
  );
};
