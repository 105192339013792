import React from "react";
import ImageGallery from "react-image-gallery";
import "./modal.css";

export const Modal = ({ setShowGallery, images, indexImg }) => {
  console.log("Imagen para mostrar: " + images);

  return (
    <div className="modal">
      <div className="modal-content">
        <ImageGallery
          startIndex={indexImg}
          items={images}
          showThumbnails={false}
          showFullscreenButton={false} // Oculta el botón de pantalla completa si no lo necesitas
          showPlayButton={false}
        />
      </div>
      <button onClick={() => setShowGallery(false)}>Cerrar</button>
    </div>
  );
};
