import React from "react";
import "./loading.css"; // Puedes usar una clase CSS para estilizar

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

export default LoadingSpinner;
