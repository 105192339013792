import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaSun, FaMoon } from "react-icons/fa";

export const HeaderNav = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Estado para el menú desplegable

  const toggleTheme = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("light-theme");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Alterna entre mostrar y ocultar el menú
  };

  const closeMenu = () => {
    setMenuOpen(false); // Cierra el menú
  };

  return (
    <header className="header">
      <NavLink to={"/inicio"}>
        <img src={"/images/logo_portafolio.png"} alt="Logo" />
      </NavLink>

      <div
        className={`menu-toggle ${menuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav className={menuOpen ? "active" : ""}>
        <ul>
          <li>
            <NavLink
              to={"/inicio"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={closeMenu}
            >
              Inicio
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/proyectos"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={closeMenu}
            >
              Proyectos
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/about"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={closeMenu}
            >
              Sobre mí
            </NavLink>
          </li>
          <li>
            <NavLink
              to={"/contacto"}
              className={({ isActive }) => (isActive ? "active" : "")}
              onClick={closeMenu}
            >
              Contacto
            </NavLink>
          </li>
          <li>
            <button onClick={toggleTheme} className="theme-toggle">
              {darkMode ? <FaMoon /> : <FaSun />}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};
