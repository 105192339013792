// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDq1gqTOSLuTrh__jCAFfqbPoBCJdY90n4",
  authDomain: "portafolio-2d59e.firebaseapp.com",
  projectId: "portafolio-2d59e",
  storageBucket: "portafolio-2d59e.appspot.com",
  messagingSenderId: "218808672100",
  appId: "1:218808672100:web:ef26e60a5c8a0eed28a471",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

export { app, db };
