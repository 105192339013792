import React from "react";
import { Link } from "react-router-dom";
import { useData } from "../context/ProjectContext";
import LoadingSpinner from "./loading/LoadingSpinner";

export const ListadoTrabajo = ({ limit, activeTab }) => {
  const { projects, stacks, loading } = useData();

  // Filtrar proyectos según la categoría activa
  const filterWorks = projects.filter((doc) =>
    doc.data().categoria.includes(activeTab)
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <section className="works">
      {filterWorks.length > 0 ? (
        filterWorks.slice(0, limit).map((trabajo, index) => (
          <article key={index} className="work-item">
            <div className="mask">
              <Link to={`/proyecto/${trabajo.data().nombre}`}>
                <img
                  src={trabajo.data().portada}
                  alt={trabajo.data().nombre}
                  key={trabajo.data().portada} //Forzar que la imagen se actualice cuando cambi
                />
                <div className="info">
                  <span>{trabajo.data().categoria}</span>
                  <h2>{trabajo.data().nombre}</h2>
                  <div className="stacks">
                    {trabajo
                      .data()
                      .tecnologias.slice(0, 3)
                      .map((r) =>
                        stacks.get(r) ? (
                          <h3 key={r}>{stacks.get(r).nombre}</h3>
                        ) : null
                      )}
                  </div>
                </div>
              </Link>
            </div>
          </article>
        ))
      ) : (
        <h3>No existen proyectos</h3>
      )}
    </section>
  );
};
