import React from "react";
import "./about.css";

export const About = () => {
  return (
    <div className="page">
      <h1 className="heading">Sobre mi</h1>
      <hr />
      <h2>Experiencia</h2>
      <ul>
        <li>
          <div className="title_exp">
            <h3>EJECUTIVO VENTAS / ECRGROUP</h3>
            <h3>03 2024 – 06 2024</h3>
          </div>
          <p>
            GENERACIÓN DE VENTAS Y EL SERVICIO AL CLIENTE. ACTÚO COMO
            REPRESENTANTE DE LA EMPRESA, VISITANDO Y ATENDIENDO A CLIENTES
            ASIGNADOS, ASÍ COMO APTANDO NUEVOS CLIENTES. REGISTRO DIARIO DE
            DATOS DE VENTAS SEGÚN LOS PROCEDIMIENTOS ESTABLECIDOS POR LA
            EMPRESA, MANTENIENDO CONFIDENCIALIDAD DE LA INFORMACIÓN.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>ANALISTA DESARROLLADOR / AMISOFT</h3>
            <h3>05 2022 – 07 2022</h3>
          </div>
          <p>
            PARTICIPÉ EN EL DESARROLLO DE UN SITIO WEB DE USO INTERNO PARA EL
            PODER JUDICIAL DE CHILE, UTILIZANDO JAVA, SPRING BOOT Y HTML.
            CONTRIBUÍ A LA IMPLEMENTACIÓN DE FUNCIONALIDADES QUE PERMITÍAN LA
            SELECCIÓN Y GESTIÓN DE DISTINTOS TIPOS DE DEMANDAS Y PROCESOS
            LEGALES, ASEGURANDO EL CUMPLIMIENTO DE ESTÁNDARES DE CALIDAD Y
            SEGURIDAD.
          </p>
        </li>
        <br />
        <li>
          <div className="title_exp">
            <h3>DESARROLLADOR MOBILE REACT NATIVE / JYF INDUSTRIAL</h3>
            <h3>01 2021 – 08 2021</h3>
          </div>
          <p>
            APLICACIÓN MÓVIL PARA MICRO EMPRESA JYF INDUSTRIAL, DESARROLLADA CON
            REACT NATIVE EXPO, CON BASE DE DATOS REMOTA ALMACENADA EN HOSTING DE
            NINJAHOSTING. UTILIZANDO REACT NATIVE 0.64.3, EXPO SDK 44, AXIOS,
            VISUAL CODE, MYSQL, SQLITE Y PHP PARA LA CONEXIÓN ENTRE AMBAS. ESTE
            DESARROLLO FUE UNA ADAPTACIÓN PARA EL FUNCIONAMIENTO DE LA APP EN
            IOS.
          </p>
        </li>
      </ul>
      <br />
      <hr />
      <h2>Educación</h2>

      <ul className="education">
        <li>
          <span>2017 – 2020</span>
          <span>Ingeniería en Informática</span>
          <span>INACAP</span>
        </li>

        <li>
          <span>2024 – Actual</span>
          <span>Full Stack JavaScript</span>
          <span>Desafío Latam</span>
        </li>
      </ul>
      <br />
      <hr />
      <h2>Cursos</h2>
      <ul className="education">
        <li>
          <span>2023 – 2024</span>
          <span>Aplicación React Native – Node.js Express</span>
          <span>Udemy</span>
        </li>
        <li>
          <span>2024 – 2024</span>
          <span>Ecommerce React – Next.js – Strapi</span>
          <span>Udemy</span>
        </li>
      </ul>
    </div>
  );
};
