import "./App.css";
import { DataProvider } from "./context/ProjectContext";
import { MisRutas } from "./router/MisRutas";

function App() {
  return (
    <div className="layout">
      <DataProvider>
        <MisRutas />
      </DataProvider>
    </div>
  );
}

export default App;
