import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import "./inicio.css";

export const Inicio = () => {
  return (
    <div className="home">
      <section className="resume">
        <h1>Hola, soy Bryan Riveros</h1>

        <h2 className="title">
          Desarrollador Full Stack con experiencia en{" "}
          <strong>JavaScript, React Native, React y Node.js.</strong>{" "}
          Especializado en crear interfaces responsivas y soluciones backend
          eficientes. Apasionado por la tecnología y en constante aprendizaje
          para aportar valor en cada proyecto.{" "}
          <Link to={"/contacto"}>Contacta conmigo.</Link>
        </h2>
        <br />

        <Link className="about" to={"/about"}>
          Más acerca de mi
          <BsArrowRight className="arrow" />
        </Link>
      </section>
    </div>
  );
};
