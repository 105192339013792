import React, { useEffect, useState } from "react";
import { ListadoTrabajo } from "../ListadoTrabajo";
import "./proyectos.css";

export const Proyectos = () => {
  const [activeTab, setActiveTab] = useState("web");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Estado para verificar si es móvil

  // Función para verificar el tamaño de la pantalla
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Actualiza si es móvil o no
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize); // Escucha el cambio de tamaño de la ventana
    return () => {
      window.removeEventListener("resize", handleResize); // Limpia el listener cuando el componente se desmonta
    };
  }, []);

  return (
    <div className="page">
      <h1 className="heading">Proyectos</h1>
      <section>
        <nav className="tab-bar">
          <button
            className={activeTab === "web" ? "select" : ""}
            onClick={() => setActiveTab("web")}
          >
            {isMobile ? "Web" : "Desarrollo Web"}
          </button>
          <button
            className={activeTab === "mobile" ? "select" : ""}
            onClick={() => setActiveTab("mobile")}
          >
            {isMobile ? "Mobile" : "Desarrollo Mobile"}
          </button>
          <button
            className={activeTab === "game" ? "select" : ""}
            onClick={() => setActiveTab("game")}
          >
            {isMobile ? "Videojuegos" : "Desarrollo Videojuego"}
          </button>
        </nav>

        {/* Contenido dinámico basado en la pestaña seleccionada */}
        <div className="tab-content">
          <ListadoTrabajo activeTab={activeTab} />
        </div>
      </section>
    </div>
  );
};
