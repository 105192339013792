import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase"; // Asegúrate de que la ruta es correcta

export const getData = async (name) => {
  try {
    const collections = collection(db, name);
    const result = await getDocs(collections);
    return result.docs; // Devuelve los documentos de la colección
  } catch (error) {
    console.error("Error al obtener la colección:", error);
    return []; // Devuelve un array vacío en caso de error
  }
};
