// Dentro de tu contexto (DataContext)

import React, { createContext, useContext, useState, useEffect } from "react";
import { getData } from "../utils/db/getCollection";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [stacks, setStacks] = useState(new Map());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Cargar datos de proyectos y stacks desde Firestore
    const fetchData = async () => {
      try {
        const projectsData = await getData("projects");
        setProjects(projectsData);

        const stacksData = await getData("stack");
        const stacksMap = new Map();

        stacksData.forEach((stack) => {
          const data = stack.data();
          stacksMap.set(data.id, data);
        });

        setStacks(stacksMap);
        setLoading(false);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <DataContext.Provider value={{ projects, stacks, loading }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  return useContext(DataContext);
};
