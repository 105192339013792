import React from "react";
import { BsGithub, BsBehance, BsLinkedin } from "react-icons/bs";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="rrss">
        <a
          href="https://www.linkedin.com/in/bryan-riveros-paredes/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsLinkedin className="icon" />
        </a>
        <a
          href="https://github.com/riveros0302"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGithub className="icon" />
        </a>
        <a
          href="https://www.behance.net/applicadevelop"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsBehance className="icon" />
        </a>
      </div>
      Portafolio Bryan Riveros &copy; FullStack JavaScript - 2024
    </footer>
  );
};
