import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../context/ProjectContext";
import "react-image-gallery/styles/css/image-gallery.css";
import "./proyecto.css";
import { Modal } from "./Modal";
import { BsGooglePlay, BsGithub, BsGlobe, BsSteam } from "react-icons/bs";

export const Proyecto = () => {
  const { projects, stacks, loading } = useData();
  const [proyecto, setProyecto] = useState(null);
  const [showGallery, setShowGallery] = useState(false); // Nuevo estado para controlar la visualización
  const [indexImg, setIndexImg] = useState(0);
  const params = useParams();

  useEffect(() => {
    if (!loading && projects.length > 0) {
      const foundProject = projects.find(
        (project) => project.data().nombre === params.id
      );
      setProyecto(foundProject ? foundProject.data() : null);
    }
  }, [loading, projects, params.id]);

  if (loading) {
    return <div>Cargando proyecto...</div>;
  }

  if (!proyecto) {
    return <div>Proyecto no encontrado</div>;
  }

  const images = proyecto.imagenes.map((img) => ({
    original: img,
    thumbnail: img,
  }));

  const showModalImage = (index) => {
    setIndexImg(index);
    setShowGallery(true);
  };

  return (
    <div className="page page-work">
      <h1 className="heading">{proyecto.nombre}</h1>
      <p>
        Tecnologías:{" "}
        <strong>
          {proyecto.tecnologias
            .map((techId) => stacks.get(techId)?.nombre)
            .filter((nombre) => nombre)
            .join(", ")}
        </strong>
      </p>
      <p>{proyecto.descripcion}</p>

      {/* Mostrar solo miniaturas */}
      <div className="gallery-thumbnails">
        {images.map((img, index) => (
          <img
            key={index}
            src={img.thumbnail}
            alt={proyecto.nombre}
            onClick={() => showModalImage(index)} // Al hacer clic, se muestra la imagen en grande
            className="thumbnail-img"
          />
        ))}
      </div>

      {/* Mostrar la galería completa cuando se selecciona una miniatura */}
      {showGallery && (
        <Modal
          setShowGallery={setShowGallery}
          images={images}
          indexImg={indexImg}
        />
      )}

      <div className="links">
        {proyecto.store && (
          <div className="btnStore">
            {proyecto.store.includes("google") ? (
              <BsGooglePlay className="icon" />
            ) : (
              <BsSteam className="icon" />
            )}

            <a href={proyecto.store} target="_blank" rel="noopener noreferrer">
              {proyecto.store.includes("google")
                ? "Ir a Google Play"
                : "Ir a Steam"}
            </a>
          </div>
        )}

        {proyecto.github && (
          <div className="btnGit">
            <BsGithub className="icon" />
            <a href={proyecto.github} target="_blank" rel="noopener noreferrer">
              Ir a GitHub
            </a>
          </div>
        )}

        {proyecto.pages && (
          <div className="btnStore">
            <BsGlobe className="icon" />
            <a href={proyecto.pages} target="_blank" rel="noopener noreferrer">
              Ver Pagina
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
